// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyAALVIV4xXwOQS7t_rU9_VfhTIyyj807nI",
    authDomain: "simplified-form-system.firebaseapp.com",
    projectId: "simplified-form-system",
    storageBucket: "simplified-form-system.appspot.com",
    messagingSenderId: "1025307609471",
    appId: "1:1025307609471:web:9331860e2cbd48c88fb24a"
  };

const firebaseConfigProd = {
    apiKey: "AIzaSyCCQSxS0yeWcSDufm3D7Y1Fc-ghNT7Qmq4",
    authDomain: "simplified-form-system-prod.firebaseapp.com",
    projectId: "simplified-form-system-prod",
    storageBucket: "simplified-form-system-prod.appspot.com",
    messagingSenderId: "949572107915",
    appId: "1:949572107915:web:996a14ccdc118131295e8e"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfigProd);
export const auth = getAuth(app);
export default app;
