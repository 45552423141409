

export const englishLang = {
    authPage: {
        title: "Verify your email address",
        linkInstructionsParagraph: "A link to sign in will be sent to your email",
        emailPlaceHolder: "Email Address",
        spanMsg1: "Email link sent…",
        spanMsg2: "Verify my email",
        alertMsgParagraph: "Thanks for confirming your email address, check for an email from noreply@simplifiedsystemprocess.com to sign in using this email address.",
        alertMsgNoRefresh: "Please do not refresh the page or click more than once."
    },

    authPageSms: {
        title: "Verify your phone number",
        instructions: "You will receive an SMS message with 6 digit one time passcode code to sign in.",
        phoneLabel: "Phone Number",
        otpTitle: "Enter a verification code",
        phonePlaceHolder: "Phone Number",
        spanMsgPhone1: "Verification code sent...",
        spanMsgPhone2: "Get Verification code",
        otpPlaceHolder: "Enter code",
        signInButton: "Sign In",
        alertMsgParagraphPhone: "Thanks for confirming your phone number.  You will receive a text message with a one time passcode to sign in to simplifiedformprocess.",
        alertMsgNoRefreshPhone: "Please do not refresh the page or click more than once."
    },
    
    patientInfoPage: {
        title: "Patient Information",
        formLabelFname: "First Name",
        formLabelLastName: "Last Name",
        formLabelAddress: "Address",
        formLabelCity: "City",
        formLabelState: "State",
        formLabelZip: "Zip",
        formLabelPhone: "Phone",
        formLabelEmail: "Email",
        button: "Proceed to sign documents"
    },
    
    patientId: {
        title: "Patient Identification Documents",
        instructionsTitle: "How to take a correct picture of your ID",
        button: "Next",
        instruction1: "Don’t upload the same document twice. You must upload a separate    document for each.",
        instruction2: "Use only legible, valid and current documents. Make sure the document isn’t damaged or illegible.",
        instruction3: "Use only unexpired documents.",
        instruction4: "Prevent image errors. When taking a digital photo of your document, be sure that all four corners are visible, and that there is no glare or other obstruction.",
        instruction5: "Submit both the front and back of documents when prompted. Some documents (like driver’s licenses) have important information on the back.  Submit an image of the back of the document whenever prompted.",
    },
    
    patientInvoice: {
        title: "Contract Details",
        button: "Finish"
    },
    
    patientContract: {
        title: "Patient Contract",
        button: "Finish"
    },
    
    
    confirmation: {
        Header: "Thank you",
        confirmationMessage: "Your documents have been successfully signed and submitted",
    },
    
    
    Error401: {
        title: "401 Not Authorized"
    },
    
    Error401LinkExpired: {
        title: "The link has expired. Please contact your sales coordinator for a new link."
    },
    
    Error401LinkCancelled: {
        title: "The link has been cancelled. Please contact your sales coordinator for a new link."
    },
    
    Error401StepsComplete: {
        title: "Process has been completed and documents have been submitted.  No further action required.  Please contact your sales coordinator for any additional questions."
    },
    
    Error403: {
        title: "403 Forbidden"
    },
    
    Error404: { 
        title: "404 Not Found"
    }
}