
export const spanishLang = {
    authPage: {
        title: "Verifique su correo electrónico",
        linkInstructionsParagraph: "Se enviará un enlace a su correo electrónico para iniciar sesión",
        emailPlaceHolder: "Dirección de correo electrónico",
        spanMsg1: "Enlace de correo electrónico enviado...",
        spanMsg2: "Verificar mi correo electrónico",
        alertMsgParagraph: "Gracias por confirmar su dirección de correo electrónico, recibirás un correo electrónico de noreply@simplifiedsystemprocess.com para registrarse.",
        alertMsgNoRefresh: "Por favor no refresque la página o haga 'click' al botón más de una vez."
    },

    authPageSms: {
        title: "Verifique su número de teléfono",
        instructions: "Recibirá un mensaje SMS con un código de acceso único de 6 dígitos para iniciar sesión",
        phoneLabel: "Número de teléfono",
        otpTitle: "Ingrese un código de verificación",
        phonePlaceHolder: "Número de teléfono",
        spanMsgPhone1: "Código enviado...",
        spanMsgPhone2: "Obtenga el código",
        otpPlaceHolder: "Ingrese el codigo",
        signInButton: "Iniciar sesión",
        alertMsgParagraphPhone: "Gracias por confirmar tu número de teléfono. Recibirá un mensaje de texto con un código de acceso único para iniciar sesión en simplifiedsystemprocess.",
        alertMsgNoRefreshPhone: "Por favor no refresque la página o haga 'click' al botón más de una vez."
    },
    
    patientInfoPage: {
        title: "Información del paciente",
        formLabelFname: "Nombre",
        formLabelLastName: "Apellido",
        formLabelAddress: "Dirección",
        formLabelCity: "Ciudad",
        formLabelState: "Estado",
        formLabelZip: "Código Postal",
        formLabelPhone: "Teléfono",
        formLabelEmail: "Correo electrónico",
        formLabelPaymentType: "Tipo de pago",
        dropDownOptionDeposit: "Depósito",
        dropDownOptionPayment: "Pago parcial",
        radioLabelTitle: "¿Quién paga?",
        radioLabelOptionPatient: "Paciente",
        radioLabelOptionPayor: "Otra persona",
        formLabelAmount: "Monto del pago",
        formFeedBackMsg: "la cantidad ingresada debe estar entre $250 y $1000",
        formFeedBackMsgPartialPayment: "la cantidad ingresada debe estar entre $500 y $20,000",
        button: "Proceder a firmar los documentos"
    },
    
    patientId: {
        title: "Documentos de Identificación del Paciente",
        instructionsTitle: "Cómo tomar una foto correcta de tu DNI",
        button: "Siguiente",
        instruction1: "No cargue el mismo documento dos veces. Debe cargar un documento separado para cada uno.",
        instruction2: "Utilizar solo documentos legibles, válidos y actualizados. Asegúrese de que el documento no esté dañado o sea ilegible.",
        instruction3: "Utilice solo documentos no vencidos.",
        instruction4: "Evitar errores de imagen. Al tomar una foto digital de su documento, asegúrese de que las cuatro esquinas estén visibles y de que no haya deslumbramiento ni otra obstrucción.",
        instruction5: "Envíe tanto el anverso (Front) como el reverso (Back) de los documentos cuando se le solicite. Algunos documentos (como las licencias de conducir) tienen información importante en el reverso.  Envíe una imagen del reverso del documento cada vez que se le solicite.",
    },
    
    patientInvoice: {
        title: "Detalles del Contrato",
        button: "Terminar"
    },
    
    patientContract: {
        title: "Contrato del paciente",
        button: "Terminar"
    },
    
    confirmation: {
        Header: "Gracias",
        confirmationMessage: "Sus documentos han sido firmados y enviados exitosamente",
    },
    
    
    Error401: {
        title: "401 No autorizado"
    },
    
    Error401LinkExpired: {
        title: "El enlace ha caducado. Póngase en contacto con su coordinador de cirugia para obtener un nuevo enlace."
    },
    
    Error401LinkCancelled: {
        title: "El enlace ha sido cancelado. Póngase en contacto con su coordinador de cirugia para obtener un nuevo enlace."
    },
    
    Error401StepsComplete: {
        title: "El proceso se ha completado y los documentos han sido enviados.  No se requiere ninguna otra acción.  Póngase en contacto con su coordinador de cirugia si tiene más preguntas."
    },
    
    Error403: {
        title: "403 Enlace Prohibido"
    },
    
    Error404: { 
        title: "404 Enlace No encontrado"
    },
    
}