import i18n from 'i18next';
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { englishLang } from './LanguageTemplates/EnglishTemp';
import { spanishLang } from './LanguageTemplates/SpanishTemp';

i18n
 .use(Backend)
 .use(LanguageDetector)
 .use(initReactI18next).init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                // ENGLISH
                //Auth Page
                authTitle: englishLang.authPage.title,
                authLinkInstruction: englishLang.authPage.linkInstructionsParagraph,
                authEmailPlaceHolder: englishLang.authPage.emailPlaceHolder,
                authSpanButtonMsg1: englishLang.authPage.spanMsg1,
                authSpanButtonMsg2: englishLang.authPage.spanMsg2,
                authMessageCheckEmail: englishLang.authPage.alertMsgParagraph,
                authMessageNoRefreshPage: englishLang.authPage.alertMsgNoRefresh,

                //Auth Phone
                authPhoneTitle: englishLang.authPageSms.title,
                authPhoneInstructions: englishLang.authPageSms.instructions,
                authPhoneLabel: englishLang.authPageSms.phoneLabel,
                enterCodeTitle: englishLang.authPageSms.otpTitle,
                authPhonePlaceholder: englishLang.authPageSms.phonePlaceHolder,
                authPhoneSpanMsg1: englishLang.authPageSms.spanMsgPhone1,
                authPhoneSpanMsg2: englishLang.authPageSms.spanMsgPhone2,
                authPhoneOtpPlaceHolder: englishLang.authPageSms.otpPlaceHolder,
                authPhoneButton: englishLang.authPageSms.signInButton,
                authPhoneParagraph: englishLang.authPageSms.alertMsgParagraphPhone,
                authPhoneNoRefreshPage:englishLang.authPageSms.alertMsgNoRefreshPhone,

                //Patient Info
                patientInfoTitle: englishLang.patientInfoPage.title,
                patientInfoFormFname: englishLang.patientInfoPage.formLabelFname,
                patientInfoFormLastname: englishLang.patientInfoPage.formLabelLastName,
                patientInfoFormAddress: englishLang.patientInfoPage.formLabelAddress,
                patientInfoFormCity: englishLang.patientInfoPage.formLabelCity,
                patientInfoFormState: englishLang.patientInfoPage.formLabelState,
                patientInfoFormZip: englishLang.patientInfoPage.formLabelZip,
                patientInfoFormPhone: englishLang.patientInfoPage.formLabelPhone,
                patientInfoFormEmail: englishLang.patientInfoPage.formLabelEmail,
                patientInfoFormPaymentType: englishLang.patientInfoPage.formLabelPaymentType,
                patientInfoDropDownDeposit: englishLang.patientInfoPage.dropDownOptionDeposit,
                patientInfoDropDownPayment: englishLang.patientInfoPage.dropDownOptionPayment,
                patientInfoRadioLabelTitle: englishLang.patientInfoPage.radioLabelTitle,
                patientInfoRadioLabelOptionPatient: englishLang.patientInfoPage.radioLabelOptionPatient,
                patientInfoRadioLabelOptionPayor: englishLang.patientInfoPage.radioLabelOptionPayor,
                patientInfoRadioFormAmount: englishLang.patientInfoPage.formLabelAmount,
                patientInfoFormFeedBackMsg: englishLang.patientInfoPage.formFeedBackMsg,
                patientInfoFormFeedBackMsgPartialPayment: englishLang.patientInfoPage.formFeedBackMsgPartialPayment,
                patientInfoAgreeBtn: englishLang.patientInfoPage.button,

                //Patient Id
                patientIdTitle: englishLang.patientId.title,
                patientIdInstructionTitle: englishLang.patientId.instructionsTitle,
                patientIdBtn: englishLang.patientId.button,
                patientIdInstruction1: englishLang.patientId.instruction1,
                patientIdInstruction2: englishLang.patientId.instruction2,
                patientIdInstruction3: englishLang.patientId.instruction3,
                patientIdInstruction4: englishLang.patientId.instruction4,
                patientIdInstruction5: englishLang.patientId.instruction5,

                //Patient Invoice
                patientInvoiceTitle: englishLang.patientInvoice.title,
                patientInvoiceBtn: englishLang.patientInvoice.button,

                //Patient Contract
                patientContractTitle: englishLang.patientContract.title,
                patientContractBtn: englishLang.patientContract.button,

                //Confirmation
                confirmationHeader: englishLang.confirmation.Header,
                confirmationFormsMessage: englishLang.confirmation.confirmationMessage,

                //Error Pages
                error401Title: englishLang.Error401.title,
                error401LinkExpiredTitle: englishLang.Error401LinkExpired.title,
                error401LinkCancelledTitle: englishLang.Error401LinkCancelled.title,
                error401StepsCompleteTitle: englishLang.Error401StepsComplete.title,
                error403Title: englishLang.Error403.title,
                error404Title: englishLang.Error404.title,

            }
        },
        es: {
            translation: {
                //SPANISH
                //Auth Page
                authTitle: spanishLang.authPage.title,
                authLinkInstruction: spanishLang.authPage.linkInstructionsParagraph,
                authEmailPlaceHolder: spanishLang.authPage.emailPlaceHolder,
                authSpanButtonMsg1: spanishLang.authPage.spanMsg1,
                authSpanButtonMsg2: spanishLang.authPage.spanMsg2,
                authMessageCheckEmail: spanishLang.authPage.alertMsgParagraph,
                authMessageNoRefreshPage: spanishLang.authPage.alertMsgNoRefresh,

                //Auth Phone
                authPhoneTitle: spanishLang.authPageSms.title,
                authPhoneInstructions: spanishLang.authPageSms.instructions,
                authPhoneLabel: spanishLang.authPageSms.phoneLabel,
                enterCodeTitle: spanishLang.authPageSms.otpTitle,
                authPhonePlaceholder: spanishLang.authPageSms.phonePlaceHolder,
                authPhoneSpanMsg1: spanishLang.authPageSms.spanMsgPhone1,
                authPhoneSpanMsg2: spanishLang.authPageSms.spanMsgPhone2,
                authPhoneOtpPlaceHolder: spanishLang.authPageSms.otpPlaceHolder,
                authPhoneButton: spanishLang.authPageSms.signInButton,
                authPhoneParagraph: spanishLang.authPageSms.alertMsgParagraphPhone,
                authPhoneNoRefreshPage: spanishLang.authPageSms.alertMsgNoRefreshPhone,

                //Patient Info
                patientInfoTitle: spanishLang.patientInfoPage.title,
                patientInfoFormFname: spanishLang.patientInfoPage.formLabelFname,
                patientInfoFormLastname: spanishLang.patientInfoPage.formLabelLastName,
                patientInfoFormAddress: spanishLang.patientInfoPage.formLabelAddress,
                patientInfoFormCity: spanishLang.patientInfoPage.formLabelCity,
                patientInfoFormState: spanishLang.patientInfoPage.formLabelState,
                patientInfoFormZip: spanishLang.patientInfoPage.formLabelZip,
                patientInfoFormPhone: spanishLang.patientInfoPage.formLabelPhone,
                patientInfoFormEmail: spanishLang.patientInfoPage.formLabelEmail,
                patientInfoFormPaymentType: spanishLang.patientInfoPage.formLabelPaymentType,
                patientInfoDropDownDeposit: spanishLang.patientInfoPage.dropDownOptionDeposit,
                patientInfoDropDownPayment: spanishLang.patientInfoPage.dropDownOptionPayment,
                patientInfoRadioLabelTitle: spanishLang.patientInfoPage.radioLabelTitle,
                patientInfoRadioLabelOptionPatient: spanishLang.patientInfoPage.radioLabelOptionPatient,
                patientInfoRadioLabelOptionPayor: spanishLang.patientInfoPage.radioLabelOptionPayor,
                patientInfoRadioFormAmount: spanishLang.patientInfoPage.formLabelAmount,
                patientInfoFormFeedBackMsg: spanishLang.patientInfoPage.formFeedBackMsg,
                patientInfoFormFeedBackMsgPartialPayment: spanishLang.patientInfoPage.formFeedBackMsgPartialPayment,
                patientInfoAgreeBtn: spanishLang.patientInfoPage.button,

                //Patient Id
                patientIdTitle: spanishLang.patientId.title,
                patientIdInstructionTitle: spanishLang.patientId.instructionsTitle,
                patientIdBtn: spanishLang.patientId.button,
                patientIdInstruction1: spanishLang.patientId.instruction1,
                patientIdInstruction2: spanishLang.patientId.instruction2,
                patientIdInstruction3: spanishLang.patientId.instruction3,
                patientIdInstruction4: spanishLang.patientId.instruction4,
                patientIdInstruction5: spanishLang.patientId.instruction5,


                //Patient Invoice
                patientInvoiceTitle: spanishLang.patientInvoice.title,
                patientInvoiceBtn: spanishLang.patientInvoice.button,

                //Patient Contract
                patientContractTitle: spanishLang.patientContract.title,
                patientContractBtn: spanishLang.patientContract.button,
     

                //Confirmation
                confirmationHeader: spanishLang.confirmation.Header,
                confirmationFormsMessage: spanishLang.confirmation.confirmationMessage,

                //Error Pages
                error401Title: spanishLang.Error401.title,
                error401LinkExpiredTitle: spanishLang.Error401LinkExpired.title,
                error401LinkCancelledTitle: spanishLang.Error401LinkCancelled.title,
                error401StepsCompleteTitle: spanishLang.Error401StepsComplete.title,
                error403Title: spanishLang.Error403.title,
                error404Title: spanishLang.Error404.title,

            }
        }
    }
 });

 export default i18n;