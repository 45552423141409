import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { stat } from "fs";

export interface StepProduction {
  linkStepId: number;
  order: number;
  routePath: string;
  linkStepStatusId: number;
  documentUrl: string;
}

interface StepProductionState {
  stepsProductionObj: StepProduction[];
}

const initialState: StepProductionState = {
  stepsProductionObj: [],
};

export const StepProductionSlice = createSlice({
  name: "stepProduction",
  initialState,
  reducers: {
    addProductionSteps: (state, action) => {
      // function compare (a:any, b:any) {
      //   if(a.order < b.order) {
      //     return -1
      //   }
      //   if(a.order > b.order) {
      //     return 1;
      //   }
      //   return 0;
      // }
      // state.stepsProductionObj.sort(compare)
      state.stepsProductionObj.push(action.payload);
      console.log("inside redux after sort", action.payload);

    },
    // Created = 1, InProgress=2, Error=3, Completed=4
    updateStepComplete: (state, action: PayloadAction<{ routePath: string; linkStepStatusId: number }>) => {
      const { routePath, linkStepStatusId } = action.payload;
      const updatedStepProduction: any = state.stepsProductionObj[0] as StepProduction;
      for (let i = 1; i < updatedStepProduction.length; i++) {
        if (updatedStepProduction[i].routePath === routePath) {
          updatedStepProduction[i].linkStepStatusId = linkStepStatusId;
        }
      }
    },
    updateStepInProcess: (state, action: PayloadAction<{ routePath: string; linkStepStatusId: number }>) => {
      const { routePath, linkStepStatusId } = action.payload;
      const updatedStepProduction: any = state.stepsProductionObj[0] as StepProduction;
      for (let i = 1; i < updatedStepProduction.length; i++) {
        if (updatedStepProduction[i].routePath === routePath) {
          updatedStepProduction[i].linkStepStatusId = linkStepStatusId;
        }
      }
    },
    removeSteps: (state) => {
      return {stepsProductionObj: []}
    }
  },
});

export default StepProductionSlice.reducer;
export const { updateStepComplete, updateStepInProcess, addProductionSteps, removeSteps } = StepProductionSlice.actions;