import { useEffect, useState } from "react";
import Progressbar from "../Components/Progressbar/Progressbar";
import { useAppSelector } from "../store/store";

export default function AppLayout () {

  const patientObj:any = useAppSelector(state => state.patient.patientObj[0]);

  useEffect(() => {
  }, [patientObj])

  


    return(
        <>
          <Progressbar />
        </>
    )
}