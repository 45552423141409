import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  isContinue: false,
};

export const ContinueSlice = createSlice({
  name: "continue",
  initialState,
  reducers: {
    continueTrue: (state) => {
      state.isContinue = true;
    },
    continueFalse: (state) => {
       state.isContinue = false;
      },
  },
});

export default ContinueSlice.reducer;
export const { continueTrue, continueFalse } = ContinueSlice.actions;