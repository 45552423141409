import { Container, Image } from "react-bootstrap";
import AppLayout from "../Layout/AppLayout";
import { useAppSelector } from "../store/store";
import { useTranslation } from "react-i18next";
import Error401StepsComplete from "./Error401StepsComplete";
import { useEffect } from "react";

export default function Confirmation() {

  const {t, i18n} = useTranslation();
  const lng = navigator.language;

  const patientObj: any = useAppSelector((state) => state.patient.patientObj[0]);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, '', window.location.href);
      console.log("EVENT IN BACK Patient Invoice", e)
      //navigate(1);
      return () => {
        window.removeEventListener('popstate', (e: PopStateEvent) => {
          
        })
      }
    })
  }, [])

  return (
    <>
    {patientObj ?
      <>
      <AppLayout />

      <Container className="conf-container">
        <div className="conf-checkmark">
          <img src="/assets/ConfirmationCheck.png" height="135px" width="135px"></img>
        </div>
        <div className="text-ref-div">
          <h1 className="text-ref">
            <b>{t('confirmationHeader')}</b>
          </h1>
        </div>

        <div className="ref-div">
          <h2>{t('confirmationFormsMessage')}</h2>
         
        </div>
      </Container>
    </>
    :
    <>
    <Error401StepsComplete />
    </>
    }
  </>
  );
}
